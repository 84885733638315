body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

a:hover {
  text-decoration: none !important;
}

/* Markdown editor */

.editor-toolbar {
  border-top: 2px solid #dfe1e6 !important;
  border-left: 2px solid #dfe1e6 !important;
  border-right: 2px solid #dfe1e6 !important;
  background: #fafbfc;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.cm-s-easymde {
  border: 2px solid #dfe1e6 !important;
  padding: 6px !important;
  font-size: 14px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.algolia-places input {
  background-color: #fafbfc;
  border-color: #dfe1e6;
  border-radius: 3px;
  border-width: 2px;
  border-style: solid;
  box-sizing: border-box;
  color: #091e42;
  cursor: text;
  padding: 8px 6px;
  font-size: 14px;
}

.algolia-places input:focus {
  background: #fff;
}
